<template>
  <!-- begin::page-container(#소상공인정보놀이터) -->
  <main id="sme-noticeinfo" class="page-container">
    <div class="page-header">
      <div class="page-component">
        <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
        <div v-if="isMobile" class="box_select">
          <button type="button" id="select_btn" @click="click">소상공인 뉴스</button>
            <ul id="select_list">
              <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
            </ul>
        </div>
        <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
        <!-- <div v-if="isMobile" class="section-notice-arrow">
          <a href="/issue" class="btn-arrow">
            <i class="icon-arrow-prev">이전 페이지</i>
          </a>
          <a href="/issue2" class="btn-arrow">
            <i class="icon-arrow-next">다음 페이지</i>
          </a>
        </div> -->
        <div class="page-header-title">
          <h2 v-if="isMobile" class="title">소상공인 뉴스</h2>
          <h2 v-else class="title">소상공인 뉴스</h2>
        </div>
        <div class="tag-search">
          <div>
            <div class="form-group" @keyup.enter="$router.push({name:'Playground', query:{tag: plygrndTag}})">
              <input v-model="plygrndTag" type="text" class="form-control" placeholder="태그검색">
              <input type="submit" class="screen-out">
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="page-body" data-fill="true">
      <nav class="page-nav">
        <ul class="nav-list page-component">
          <li class="nav-item" :class="{'is-selected': (plygrndCtgrDcd === '')}">
<!--            <router-link :to="{name:'Playground'}"><span class="nav-text">전체</span></router-link>-->
            <router-link :to="{name:'Playground'}"></router-link>
          </li>
          <li
              v-for="(item, idx) in ctgrItems"
              class="nav-item"
              :key="idx"
              :class="{'is-selected' : item.dcd === plygrndCtgrDcd}"
          >
            <router-link :to="{name:'Playground', query:{ctgrCode:item.dcd}}" class="nav-link"><span class="nav-text">{{ item.dcdVal }}</span></router-link>
          </li>
        </ul>
      </nav>
      <div class="playground-title">
        <p v-if="$route.query.ctgrCode === '1001001'" class="playground-title-desc">
          소상공인이 알기 힘든 세무, 법률 관련 지식들! 소상공인 솔루션위원회에서 알려드립니다.
        </p>
        <p v-if="$route.query.ctgrCode === '1001002'" class="playground-title-desc">
          소상공인 제품 홍보 웹예능 프로그램 어떰유통에서 홍보하는 소상공인 상품을 만나보세요.
        </p>
        <p v-if="$route.query.ctgrCode === '1001003'" class="playground-title-desc">
          웹예능 속 광고기획자 벤자민 권이 기획한 상품들을 통해 만나는 특급 기획 콘텐츠예요.
        </p>
      </div>

      <common-list :div-class="['page-component']"
                   :is-loading="isLoading"
                   :is-no-result="isNoResult">
        <div class="post-list-wrapper notice-list-wrapper">
<!--          <div class="list-header">-->
<!--            <template v-if="isMobile">-->
<!--              <div class="filter-selector">-->
<!--                <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">-->
<!--                  <option value="">최신순</option>-->
<!--                  <option value="I">인기순</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <div class="header-column">-->
<!--                <h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>-->
<!--              </div>-->
<!--              <div class="header-column">-->
<!--                <div class="filter-selector">-->
<!--                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">-->
<!--                    <option value="">최신순</option>-->
<!--                    <option value="I">인기순</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->
          <common-card :items="refineItems" :is-tag="true" />
          <div v-if="hasMore" class="page-buttons" data-align="center">
            <button class="btn btn-lg btn-outline-white" @click="morePage">
              <span class="text">더보기</span>
            </button>
          </div>

        </div>
      </common-list>
    </div>
  </main>
  <!-- end::page-container(#소상공인정보놀이터) -->
</template>

<script>
import {ACT_GET_COMMON_CODE_LIST, ACT_GET_PLAYGROUND_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck, queryToValue, setPaging, tryResCallback, viewTags} from '../../../assets/js/utils';
// import CommonCard from '../../../components/common/CommonCard';
import CommonCard from '../../../components/common/CommonCard';
import {mapGetters} from 'vuex';
import NoResult from '../../../components/common/NoResult';
import CommonList from '../../../components/common/CommonList';
import menus from '../../../assets/js/menus';

export default {
  name: 'Playground',
  components: {
    CommonCard,
    NoResult,
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    plygrndTag: '',
    plygrndCtgrDcd: '',
    items: [],
    ctgrItems: [],
    tryCount: 0,
    menus: menus.filter(x => (x.code === 'information')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  watch:{
    $route(to){
      this.plygrndCtgrDcd = queryToValue(to.query.ctgrCode, false, '');
      this.plygrndTag = queryToValue(to.query.tag, false);
      this.changeCtgr(this.plygrndCtgrDcd);
    }
  },
  computed:{
    ...mapGetters('common', ['isMobile']),
    refineItems(){
      return this.items.map(x => ({
        ctgr: x.plygrndCtgr,
        tit: x.plygrndTit,
        tags: viewTags(x, 'plygrndTag'),
        rprsImgPtUrl: x.rprsImgPtUrl,
        viewLink: (this.$route.query.ctgrCode ? `/playground/${x.plygrndId}/view?ctgrCode=${this.$route.query.ctgrCode}`: `/playground/${x.plygrndId}/view`)
      }));
    },
    isNoResult(){
      return this.items.length === 0;
    }
  },
  created() {
    this.plygrndCtgrDcd = queryToValue(this.$route.query.ctgrCode, false, '');
    this.plygrndTag = queryToValue(this.$route.query.tag, false);
    this.getPlayGroundCtgrList()
  },
  methods: {
    getPlayGroundCtgrList(){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {dcd: '1001', masterYn: 'N'}).then(res=>{
        tryResCallback(this.tryCount, res, this.getPlayGroundCtgrList, this.setPlayGroundCtgr);
      }).catch(e => {
        console.error(e);
      });
    },
    setPlayGroundCtgr(res) {
      if(lengthCheck(res)) this.ctgrItems = getItems(res);
      this.getPlaygroundList(true);
    },
    showDetail(arg) {
      console.log(arg);
    },
    getPlaygroundList(isInit) {
      if(isInit) {
        this.items = [];
        this.isLoading = true;
        this.pageNo = 1;
      }
      this.$store.dispatch(`information/${ACT_GET_PLAYGROUND_LIST}`, {
        plygrndTag: this.plygrndTag,
        plygrndCtgrDcd: this.plygrndCtgrDcd,
        sort: this.sort,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        } else {
          setPaging(this, res);
        }
        this.isLoading = false;
      }).catch(e=>{
        console.error(e);
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getPlaygroundList();
    },
    selectSort() {
      this.getPlaygroundList(true);
    },
    changeCtgr(dcd) {
      this.plygrndCtgrDcd = dcd;
      this.getPlaygroundList(true);
    },
    searchTagKeyword(){
      this.getPlaygroundList(true);
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
